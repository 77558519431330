<template>
  <div>
    <div style="padding: 10vh 0 0 0;text-align: center">
      <div>
        <el-button class="button" @click="jump(1)">搜索记录</el-button>
      </div>
      <div style="padding: 30px 0 0 0;">
        <el-button class="button" @click="jump(2)">下载统计</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods:{
    jump(type){
      switch (type){
        case 1:
          this.$router.push({name:'statistics_search'})
          break
        case 2:
          this.$router.push({name:'statistics_download'})
          break
      }
    }
  }
}
</script>