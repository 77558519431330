export default {
    filesizeText(fileSize){
        let fileSizeText=(fileSize/1024/1024/1024).toFixed(0)
        if(fileSizeText<1)
        {
            fileSizeText=(fileSize/1024/1024).toFixed(0)
            if(fileSizeText<1)
            {
                fileSizeText=(fileSize/1024).toFixed(0)
                if(fileSizeText<1)
                {
                    fileSizeText=fileSize+'B'
                }else{
                    fileSizeText=fileSizeText+'KB'
                }
            }else{
                fileSizeText=fileSizeText+'MB'
            }
        }else{
            fileSizeText=fileSizeText+'GB'
        }
        return fileSizeText
    },
    //_downloads/杂宝藏经白话.pdf => 杂宝藏经白话.pdf
    getFileName({path,sep='/'}){
        return path.slice(path.lastIndexOf('/')+1)
    }
}