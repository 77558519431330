<template>
  <div>
    <div v-show="show"
         style="position: fixed;top: 0;left: 0;bottom: 0;right: 0;background-color: rgba(113,111,111,0.47);z-index:99999;color:white;text-align: center;">
      <div style="color: white;background-color: rgba(135,134,134,0.88);padding: 20px 0;">
        <div>{{ text }}</div>
        <div style="padding: 10px 10px 0;">
          <el-progress :percentage="percentage" :show-text="false"></el-progress>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import android from "../../extend/android";

export default {
  data() {
    return {
      text: '正在下载，请先不要操作或退出',
      percentage: 0,
      show: false,
    }
  },
  watch: {
    '$store.state.downloading': {
      deep: true,
      handler(n) {
        if (this.show === false) {
          this.show = true
        }
        if (n.totalSize === 0) {
          this.percentage = 0
        } else {
          this.percentage = (n.downloadedSize / n.totalSize * 100).toFixed(0) * 1
        }
        if (n.state === 4) {
          if (n.expectFileSize * 1 === n.totalSize * 1) {
            // move文件失败 暂时不做
            // if(this.$route.query.platform==='android'){
            //   this.moveToDownloadDir({
            //     source:n.localPath,
            //     success:()=>{
            //       this.$message({
            //         message: n.fileName + ' 已下载完成',
            //         type: 'success', duration: 5000
            //       })
            //     }
            //   })
            // }else{
              this.$store.commit('showDownloadConfirm',false)
              this.$message({
                message: n.fileName + ' 已下载完成',
                type: 'success', duration: 5000
              })
            //}
          } else {
            this.$message({
              message: n.fileName + ' 下载出错，请重试，若还是有问题请反馈',
              type: 'error', duration: 5000
            })
          }
          this.show = false
        }
        if (n.state === 5) {
          this.$message({
            message: n.fileName + ' 下载出错，请重试，若还是有问题请反馈',
            type: 'error', duration: 5000
          })
          this.show = false
        }
      }
    }
  },
  methods: {
    t1() {
      this.$store.commit('reportDownloading', {downloadedSize: 1, totalSize: 2, state: 3})
    },
    moveToDownloadDir({source,success,fail}){
      android.checkDownloadDir({obj:this,
        success:({path})=>{
          android.moveFile({
            source:source,
            targetDir:path,
            success:()=>{
              if(typeof success!=='undefined'){
                success()
              }
            },
            fail:(r)=>{
              this.$message({
                message:'移动文件出错，请反馈:'+JSON.stringify(r),
                type:'error',
                duration:5000,
                onClose:()=>{
                  if(typeof fail!=='undefined'){
                    fail()
                  }
                }
              })
            }
          })
        },
        fail:()=>{

        }
      })
    }
  }
}
</script>