<template>
    <div>
        <search @submit="submit" ref="r_search" style="margin: 30px 0 10px 0" :placeholders="placeholders"></search>
        <el-row>
            <el-col :span="22" :offset="1">
                <el-tabs class="main-tab" tab-position="top" style="" :before-leave="beforeLeaveTab">
                    <el-tab-pane label="推荐资料">
                        <recommend @clickRecommend="clickRecommend" :recommend-items="recommendItems"></recommend>
                    </el-tab-pane>
                    <el-tab-pane label="使用说明">
                        <div v-html="guides.mb"></div>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="22" :offset="1">
                <result @download="download" @browse="browse" @shield="shield" ref="r_result" @pageChange="pageChange" :page-size="pageSize" :total="total" :items="items" :keyword="keyword"></result>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import search from './search.vue'
    import recommend from './recommend.vue'
    import result from './result.vue'
    export default {
        props:[
            'recommendItems','pageSize','total','items','placeholders','guides'
        ],
        data(){
            return {
                keyword:null,
                currentPage:1,
            }
        },
        methods:{
            beforeLeaveTab(tabIndex){
                return this.$store.dispatch('beforeLeaveTab',{tabIndex:tabIndex})
            },
            download(item){
                this.$emit('download',item)
            },
          browse(item){
            this.$emit('browse',item)
          },
          shield(item){
            this.$emit('shield',item)
          },
            setConfAllSelected(){
                return this.$refs.r_search.setConfAllSelected()

            },
            getConf(){
                return this.$refs.r_search.getConf()
            },
            setCurrentPage(n){
                // console.log('setCurrentPage')
                this.$refs.r_result.setCurrentPage(n)
            },
            pageChange(n){
                this.currentPage=n
                this.submit(this.keyword,(this.currentPage-1)*this.pageSize,this.pageSize,this.getConf())
            },
            setKeyword(keyword){
                this.keyword=keyword
                this.$refs.r_search.setKeyword(keyword)
            },
            submit(keyword,offset,length,conf){
                this.keyword=keyword
                if(offset===undefined&&length===undefined)
                {
                    this.currentPage=1
                    this.setCurrentPage(1)
                }
                this.$emit('submit',keyword,offset,length,conf)
            },
            clickRecommend(index){
                this.$emit('clickRecommend',index)
            }
        },
        components:{
            search,recommend,result
        }
    }
</script>