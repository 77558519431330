<template>
    <div style="display: inline-block;">
        <el-alert
                v-if="status"
                :style="{padding:padding,'background-color':'unset'}"
                title="服务正常"
                type="success"
                :closable="false"
                show-icon>
        </el-alert>
        <el-alert
                :style="{padding:padding,'background-color':'unset'}"
                v-else
                title="服务维护中"
                type="error"
                :closable="false"
                show-icon>
        </el-alert>
    </div>
</template>
<script>
    export default {
        watch:{
            device:{
                immediate:true,
                handler(n){
                    if(n==='mobile')
                    {
                        this.padding='2px 5px'
                    }
                }
            }
        },
        data(){
            return {
                padding:'8px 16px'
            }
        },
        props:{
            status:{
                default:true
            },
            device:{
                default:null,
            }
        }
    }
</script>