import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import manage_kdoc_sync from '../views/manage/kdoc/sync.vue'
Vue.use(VueRouter)
import statistics_search from '../views/statistics/search.vue'
import statistics_download from '../views/statistics/download.vue'
import statistics from '../views/statistics/statistics.vue'
import shield_path from '../views/shield/path.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    components: {
      r1:Home
    }
  },
  {
    path: '/statistics/search',
    name: 'statistics_search',
    components: {
      r1:statistics_search
    }
  },
  {
    path: '/statistics/download',
    name: 'statistics_download',
    components: {
      r1:statistics_download
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    components: {
      r1:statistics
    }
  },{
    path: '/shield/path',
    name: 'shield_path',
    components: {
      r1:shield_path
    }
  },
  // {
  //   path: '/manage/kdoc/sync',
  //   name: 'manage_kdoc_sync',
  //   components: {
  //     r1:manage_kdoc_sync
  //   }
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
