export default {
    createFileNameHtml({fileName,keyword,highLightClassName}){
        //保留字符
        const reservedChars = '< spancl="FieNm-HghLt>/'.split('');
        const keys = this.arrayUnique(keyword.split(''))
        for (let i = 0; i < keys.length; i++) {
            const kw = keys[i]
            if (reservedChars.indexOf(kw) === -1) {
                const exp = new RegExp(kw, 'g')
                fileName = fileName.replace(exp, '<span class="' + highLightClassName + '">' + kw + '</span>')
            }
        }
        return fileName
    },
    arrayUnique(array) {
        var n = [];
        for (var i = 0; i < array.length; i++) {
            if (array[i] !== '') {
                if (n.indexOf(array[i]) === -1) {
                    n.push(array[i]);
                }
            }
        }
        return n;
    }
}