<style scoped>
.el-divider{
  margin:0 0 4px 0;
}
</style>
<template>
  <div v-loading="loading" style="overflow-x: hidden">
    <div style="text-align: center;padding: 10px;height: 60px;position: fixed;top:0;z-index: 1;background-color: white;width: 100%;">
      <el-button class="button" size="small" @click="get">刷新</el-button>
      <el-button class="button" size="small" @click="addShieldKeyword">新增屏蔽关键词</el-button>
    </div>
    <div style="height: 60px;"></div>
    <div style="padding:0  10px 10px 10px;">
      <div v-for="(i,index) in value" :key="index" style="padding: 0 0;">
        <div style="padding: 0 0 4px 0;">
          <div style="font-size: small;color: gray;margin:4px 0;">
            <b>#{{index+1}}</b>
            {{i.isDir?'【文件夹】':'【文件】'}} 用户{{i.accountName}}创建于{{i.createTime}}
            <el-button size="mini" type="success" @click="cancelShield(i)">取消屏蔽</el-button>
            <div>
              备注:{{i.commit}}
            </div>
          </div>
          <div>
            {{i.path}}
          </div>
        <el-divider></el-divider>
        </div>
      </div>
    </div>

    <div id="related_path" style="position: fixed;top: 0;left: 0;right: 0;bottom: 0;width: 100%;z-index: 1;overflow: scroll" v-if="showShieldKeyword" :style="{backgroundColor:relatedPath.length===0?'rgba(0,0,0,0.7)':'white'}">
      <div style="" @click.stop>
        <div style="position: fixed;background-color: white;width: 100%;padding: 20px 4px;z-index: 1;">
          <el-input v-model="shieldKeyword" placeholder="请输入要屏蔽的关键词" @keyup.enter.native="getRelatedPath" :clearable="true">
            <template #prepend>关键词</template>
            <template #append>
              <el-button @click="showShieldKeyword=false">关闭</el-button>
            </template>
          </el-input>
          <div style="text-align: center;padding: 10px;width: 100%;">
            <el-button class="button" style="width: 100%;" size="small" type="primary" @click="getRelatedPath">获取建议屏蔽的路径</el-button>
          </div>
          <div v-if="relatedPath.length!==0" style="text-align: center;padding: 0 10px;width: 100%;">
            <el-button style="width: 100%;" size="small" type="warning" @click="shieldRelatedPath">添加已勾选的建议路径至屏蔽列表</el-button>
          </div>
        </div>
        <div style="height: 160px;"></div>
        <div style="padding: 0 10px;">
          <div v-for="(i,index) in relatedPath" :key="index" style="padding: 0 0;">
            <div style="padding: 0 0 4px 0;">
              <div style="font-size: small;color: gray;margin:4px 0;display: flex">
              <span style="align-self: center">
                <b>#{{index+1}}</b>
                {{i.isDir?'【文件夹】':'【文件】'}}
              </span>
                <el-button class="button" size="mini" type="success" @click="browser(i)">浏览</el-button>
                <span style="position: absolute;right: 10px;align-self: center;">
                <span v-if="i.shielded">已屏蔽</span>
                <span v-else>
                <el-switch  v-model="i.shield" inactive-color="gray" active-color="green"></el-switch>
              </span>
              </span>
              </div>
              <div>路径：
                <span v-html="i.pathHtml"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <browser ref="browser" @shield="shield" :device="$store.state.ua.device.type"></browser>
  </div>
</template>
<script>
import request from "../../plugin/request";
import Browser from "../../components/Browser";
import $ from 'jquery'
export default {
  components: {Browser},
  data(){
    return {
      loading:false,
      value:[],
      newItem_isDir:false,
      newItem_path:null,
      newItem_commit:null,
      shieldKeyword:null,
      showShieldKeyword:false,
      showShieldPath:false,
      relatedPath:[],
    }
  },
  watch:{
    showShieldKeyword:{
      handler(n){
        if(n===false){
          this.get()
        }
        if(n===true){
          this.relatedPath=[]
          this.shieldKeyword=null
        }
      }
    }
  },
  mounted() {
    this.get()
  },
  methods:{
    shieldRelatedPath(){
      this.loading=true
      const shieldPath=[]
      //console.log(this.relatedPath)
      for (const index in this.relatedPath){
        const item=this.relatedPath[index]
        if(item.shield&&!item.shielded){
          shieldPath.push({
            path:item.path,
            isDir:item.isDir,
          })
        }
      }
      //console.log(shieldPath)
      request({
        obj:this,
        path:'shield/paths',
        data:{
          data:shieldPath,
          shieldKeyword:this.shieldKeyword
        },
        success:(r)=>{
          if(typeof r.s!=='undefined'){
            this.$message({
              message: '屏蔽成功',
              type: 'success'
            });
            this.loading=false
            this.getRelatedPath()
          }
        }
      })
    },
    browser(item){
      this.$refs.browser.show({id:item.id,
        parentPath:item.parent_path})
    },
    shield(item){
      this.$store.commit('shieldItem',item)
      this.$store.commit('showShieldConfirm',true)
    },
    cancelShield(item){
      this.loading=true
      request({
        obj:this,
        path:'shield/path/cancel',
        data:{
          path:item.path
        },
        success:(r)=>{
          if(typeof r.s!=='undefined'){
            this.$message({
              message: '取消屏蔽成功',
              type: 'success',
              onClose:()=>{
                this.loading=false
                this.get()
              }
            });
          }
        }
      })
    },
    getRelatedPath(){
      this.loading=true
      request({
        obj:this,
        data:{
          keyword:this.shieldKeyword
        },
        path:'shield/keyword/relatedPath/get',
        success:(r)=>{
          //
          const relatedPath=r.s.data
          if(r.s.data.length===0){
            this.$message({
              message: '没有相关结果',
              type: 'success',
            });
          }
          const exp=new RegExp(this.shieldKeyword,'g');
          for(let i in relatedPath){
            relatedPath[i]['pathHtml']=relatedPath[i]['path'].replace(exp,'<span class="FileName-HighLight">'+this.shieldKeyword+'</span>')
          }
          this.relatedPath=relatedPath
          this.loading=false
          // setTimeout(()=>{
          //   //const jquery=require('jquery')
          //   //jquery('#related_path').scrollTo(0,0)
          //   //var scrollTo = require('jquery/scrollto');
          //   //scrollTo(0,0)
          //   var VueScrollTo = require('vue-scrollto');
          //    VueScrollTo.scrollTo('#related_path', 500)
          // })
        }
      })
    },
    addShieldKeyword(){
      this.showShieldKeyword=true
    },
    get(){
      this.loading=true
      request({
        obj: this,
        path: 'conf/shieldPath/get',
        success: (r) => {
          this.value=r.s.value
          this.loading=false
        }
      })
    }
  }
}
</script>