import $ from 'jquery'
import conf from '../conf'
import store from '../store'
import login from '../plugin/login'
export default function ({ obj,path='',data={},success,error,complete}){
    if(typeof data.uuid==='undefined'){
        data['uuid']=store.state.uuid
    }
    if(typeof data.token==='undefined'){
        data['token']=store.state.token
    }
    //console.log(JSON.stringify(data))
    $.ajax({
        contentType: "application/json; charset=utf-8",
        timeout:600000,
        type:'POST',
        url:conf.api_domain[process.env.NODE_ENV]+path,
        data:JSON.stringify(data),
        success:(r)=>{
            if(Array.isArray(r)&&r.length===0){
                if(typeof  obj!=='undefined'&& obj.$message!=='undefined'){
                    obj.$message({
                        message: '系统出错',
                        type: 'error',
                    });
                }
            }
            else if(typeof r.code!=='undefined'&&typeof  r.msg!=='undefined'){
                if(typeof  obj!=='undefined'&& obj.$message!=='undefined'){
                    obj.$message({
                        message: r.msg+' ['+r.code+']',
                        type: 'error',
                        onClose:function(message){
                            //登录过期
                            if(['50002'].indexOf(r.code+'')>-1){
                                login.logout({})
                            }
                        }
                    });
                }
            }
            else if(typeof success!=='undefined'){
                success(r)
            }
        },
        error:(r)=>{
            if(typeof error!=='undefined'){
                error(r)
            }else{
                if(typeof  obj!=='undefined'&& obj.$message!=='undefined'){
                    obj.$message({
                        message: '服务器出错，请稍后重试',
                        type: 'error'
                    });
                }
            }
        },
        complete:(r)=>{
            if(typeof complete!=='undefined'){
                complete(r)
            }
        }
    })
}