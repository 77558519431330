<template>
  <div>
    <statistics type="search"></statistics>
  </div>
</template>
<script>
import statistics from "../../components/statistics/statistics.vue";
export default {
  components:{
    statistics
  }
}
</script>