<template>
  <div>
    <div style="font-size: small;text-align: right;padding: 10px 20px 10px 0;height: 40px;"  @click="showPicker=true">切换日期</div>
    <div>
      <ve-table
          ref="table"
          :style="{'word-break':'keep-all',width:tableWidth+'px'}"
          :virtual-scroll-option="virtualScrollOption"
          :columns="type==='download'?downloadColumns:(type==='search'?searchColumns:[])"
          :table-data="tableData"
          :max-height="maxHeightOfTable"
          row-key-field-name="rowKey"
      />
    </div>
    <div v-show="showPicker" style="position: fixed;bottom: 0;width: 100%;z-index: 1;left:0;">
      <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="confirm"
      />
    </div>

  </div>
</template>
<script>
import request from "../../plugin/request";
import login from "../../plugin/login";

export default {
  props:{
    type:null
  },
  watch:{
    tableWidth(n){
      console.log(n)
    },
    showPicker:{
      immediate:true,
      handler(n){
        console.log(n)
        if(n){
          this.tableWidth='0'
        }else{
          let columns
          switch (this.type){
            case 'search':
              columns=this.searchColumns
              break
            case 'download':
              columns=this.downloadColumns
              break
          }
          let tableWidth=0
          for (let i in columns){
            tableWidth=tableWidth+columns[i]['width']
          }
          this.tableWidth=tableWidth
        }
      }
    }
  },
  mounted() {
    this.maxHeightOfTable=window.innerHeight-40
  },
  methods:{
    confirm(){
      const year=this.currentDate.getFullYear()
      let month=this.currentDate.getMonth()+1
      month=(month+'').length===1?('0'+month):month
      let day=this.currentDate.getDate()
      day=(day+'').length===1?('0'+day):day
      //console.log([year,month,day])
      this.getData(year,month,day)
      this.showPicker=false
    },
    getData(year,month,day){
      request({
        obj: this,
        path: 'statistics/get',
        data: {
          type: this.type,
          year: year,
          month: month,
          day: day,
        },
        success: (r) => {
          this.tableData=r.s.tableData
          this.$nextTick(function(){
            this.$refs["table"].scrollTo({ left:0,top: 0, behavior: "smooth" });
          })
        }
      })
    }
  },
  data(){
    return {
      maxHeightOfTable:0,
      virtualScrollOption: {
        // 是否开启
        enable: true,
      },
      showPicker:true,
      currentDate:new Date(),
      searchColumns:[
        { field: "rowKey", key: "rowKey", title: "序号", width: 50 },
        { field: "keyword", key: "keyword", title: "关键词", width: 100,},
        { field: "datetime", key: "datetime", title: "时间", width: 400 },
        { field: "clientId", key: "clientId", title: "客户端ID", width: 100 },
        { field: "ip", key: "ip", title: "IP", width: 100 },
        { field: "uuid", key: "uuid", title: "帐号ID", width: 100 },
        { field: "area", key: "area", title: "地区", width: 100 },
        { field: "device", key: "device", title: "设备", width: 100 },
        { field: "os", key: "os", title: "系统", width: 100 },
        { field: "isp", key: "isp", title: "运营商", width: 100 },
        { field: "country", key: "country", title: "国/地区", width: 100 },
        { field: "province", key: "province", title: "省", width: 100 },
        { field: "city", key: "city", title: "市", width: 100 },
        { field: "county", key: "county", title: "县", width: 100 },
        { field: "engine", key: "engine", title: "平台", width: 100 },
       // { field: "ua", key: "ua", title: "UA", width: 100 },
        { field: "browser", key: "browser", title: "浏览器", width: 100 },
        { field: "timestamp", key: "timestamp", title: "时间戳", width: 100 },
      ],
      downloadColumns:[
        { field: "rowKey", key: "rowKey", title: "序号", width: 50 },
        { field: "ip", key: "ip", title: "IP",width: 100},
        { field: "uuid", key: "uuid", title: "帐号ID",width: 100},
        { field: "count", key: "count", title: "计数",width: 60},
        { field: "day", key: "day", title: "日期",width: 300},
        { field: "lastTime", key: "lastTime", title: "上次时间" ,width: 100},
      ],
      tableData: [],
    }
  }
}
</script>
