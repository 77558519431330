import request from '../plugin/request'
import store from "../store";
import time from '../plugin/time.js'
import { Loading } from 'element-ui';
export default {
    getNoticeDate(){
        store.commit('noticeDate',localStorage.getItem('noticeDate'))
    },
    setNoticeDate(){
        localStorage.setItem('noticeDate',time.date())
    },
    _download({obj}){
        store.commit('showDownloadConfirm',false)
        const item=store.state.currentItem
        const loading=Loading.service({
            text:'开始下载'
        });
        request({
            obj:obj,
            path:'download_2',
            data:{
                path:encodeURI(item.path+item.fileName+'.'+item.fileType),
            },
            complete:()=>{
                loading.close()
            },
            success:(r)=>{
                if(r.s1!==undefined){
                    obj.$message({message:r.s1.message,type:'error'})
                }
                if(r.s!==undefined){
                    const url=r.s.url
                    store.commit('number1',r.s.number1)
                    if(store.state.isAPP){
                        const d=plus.downloader.createDownload(url, {
                            retry:1,
                            timeout:0
                        },(r,status)=>{
                            store.commit('reportDownloading',{downloadedSize:r.downloadedSize,totalSize:r.totalSize,state:r.state,fileName:item.fileName,expectFileSize:item.fileSize,localPath:r.filename})
                            console.log('1-'+JSON.stringify(r))
                            //console.log('2-'+status)
                            //console.log('3-'+d.getAllResponseHeaders())
                        } );
                        d.addEventListener("statechanged",(r,status)=>{
                            //console.log('4-'+JSON.stringify(r))
                            store.commit('reportDownloading',{downloadedSize:r.downloadedSize,totalSize:r.totalSize,state:r.state,fileName:item.fileName,expectFileSize:item.fileSize,localPath:r.filename})
                            //console.log('5-'+status)
                        } , false);
                        d.setRequestHeader('User-Agent','pan.baidu.com')
                        d.start();
                    }else{
                        window.open(url)
                    }
                }
            }
        })
    },
    download({obj,item,c}){
        store.commit('downloadBtnRedirect',false)
        store.commit('downloadErrMsg',null)
        store.commit('downloadActualURL',null)
        store.commit('currentItem',item)
        if(store.state.noticeWhenDownload){
            const loading=Loading.service({
                text:'正在处理'
            });
            request({
                obj:obj,
                path:'download/get',
                data:{
                    path:encodeURI(item.path+item.fileName),
                    fileSize:item.fileSize,
                },
                complete:()=>{
                    store.commit('showDownloadConfirm',true)
                    loading.close()
                    if(typeof c!=='undefined'){
                        c()
                    }
                },
                success:(r)=>{
                    if(r.s1!==undefined){
                        store.commit('downloadErrMsg',r.s1.msg)
                    }
                    if(r.s2!==undefined){
                        store.commit('downloadErrMsg',r.s2.msg)
                        store.commit('downloadBtnRedirect',true)
                    }
                    if(r.s!==undefined){
                        const url=r.s.url
                        store.commit('number1',r.s.number1)
                        store.commit('downloadActualURL',url)
                    }
                }
            })
        }else{
            this._download({obj:obj,item:item})
        }
    },
}