import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
Vue.use(VueEasytable);
Vue.use(ElementUI);
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
import Vant from 'vant';
Vue.use(Vant);
// var VueScrollTo = require('vue-scrollto');
// Vue.use(VueScrollTo)
import 'vant/lib/index.css';
import './assets/style.css'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
