<template>
  <div>
    <div :style="{visibility:$store.state.loginStatus?'unset':'hidden'}" style="text-align: right">
      <status :device="device" :status="status"></status>
    </div>
    <div v-loading="loading" :style="{filter:!showLoginModal?'unset':'blur(2px)'}">
      <mb
          @download="download"
          @browse="browse"
          @shield="shield"
          ref="r_mb"
          v-if="device==='mobile'"
          @submit="submit"
          @clickRecommend="clickRecommend"
          :recommendItems="recommendItems"
          :page-size="pageSize"
          :total="total"
          :items="items"
          :placeholders="placeholders"
          :guides="guides"
      ></mb>
      <pc
          @download="download"
          @browse="browse"
          @shield="shield"
          ref="r_pc"
          v-else
          @submit="submit"
          @clickRecommend="clickRecommend"
          :recommendItems="recommendItems"
          :page-size="pageSize"
          :total="total"
          :items="items"
          :guides="guides"
          :placeholders="placeholders"
      ></pc>
      <footerC :html="footerHtmls[device]"></footerC>
    </div>
    <el-dialog
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        title=""
        :modal="true"
        :visible.sync="showLoginModal"
        :width="device==='mobile'?'80%':'30%'"
        center>
      <div>
        <div style="text-align: center">
            <img style="width:90%;" src="/img/title.png"/>
        </div>
          <el-input style="margin:30px 0 0 0;" v-model="username" placeholder="请输入用户名" clearable></el-input>
        <el-input @keyup.enter.native="login" style="margin:20px 0 0 0;" v-model="password" type="text" placeholder="请输入密码"
                  clearable></el-input>
        <el-button class="button" @click="login" type="primary" style="margin:20px 0 0 0;width:100%;" :loading="loginBtnLoading" :disabled="loginBtnLoading">登录</el-button>
        <div style="text-align: center;margin:20px 0 0 0;">
          本站仅供内部学员学习使用
        </div>
      </div>
    </el-dialog>
    <Browser ref="browser" @shield="shield" :device="device" @download="download"></Browser>
    <FloatingWindow></FloatingWindow>
<!--    下载-->
    <el-dialog
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :title="$store.state.baseData.download_confirm_title"
        :modal="true"
        :visible.sync="$store.state.showDownloadConfirm"
        :width="device==='mobile'?'90%':'30%'"
        center>
      <div>
        <div v-html="$store.state.baseData.html.download_confirm.replace('{number1}',$store.state.number1)"></div>
          <div v-if="$store.state.downloadErrMsg!==null" style="color:red;padding:10px 0 0 0;">{{$store.state.downloadErrMsg}}</div>
        <div style="margin:20px 0 0 0;">
            <div v-if="$store.state.isAPP">
                <a v-if="$store.state.downloadBtnRedirect" :href="instructionsURL" target="_blank"><el-button class="button"  style="width: 100%" type="primary">确定</el-button></a>
                <el-button class="button" v-else-if="$store.state.downloadErrMsg!==null" @click="onConfirmNotice" style="width: 100%" type="primary">确定</el-button>
                <el-button class="button" v-else style="width: 100%" type="primary" @click="downloadInAPP">下载</el-button>
            </div>
            <div v-else>
                <a v-if="$store.state.downloadBtnRedirect" :href="instructionsURL" target="_blank"><el-button class="button"  style="width: 100%" type="primary">确定</el-button></a>
                <el-button class="button" v-else-if="$store.state.downloadErrMsg!==null" @click="onConfirmNotice" style="width: 100%" type="primary">确定</el-button>
                <a v-else class="button" :href="$store.state.downloadActualURL" target="_blank"><el-button style="width: 100%" type="primary">下载</el-button></a>
            </div>
        </div>
        <div style="margin:20px 0 0 0;">
          <el-button @click="$store.commit('showDownloadConfirm',false)" style="width: 100%" type="info">关闭</el-button>
        </div>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import FloatingWindow from "../components/download/FloatingWindow";
import footerC from '../components/footer.vue'
import pc from '../components/pc/index.vue'
import mb from '../components/mb/index.vue'
import uaExtend from '../plugin/useragent.js'
import $ from 'jquery'
import Status from "../components/status"
import request from "../plugin/request";
import conf from '../conf'
import login from '../plugin/login'
import Browser from '../components/Browser'
import download from "../extend/download";
import android from "../extend/android";
import time from '../plugin/time'
import rtrim from 'underscore.string/rtrim'
import store from "@/store";
import {mapState} from 'vuex'
export default {
  name: 'Home',
  mounted() {
      // this.$alert('网站正在维修中', '提醒', {
      //     confirmButtonText: '确定',
      //     confirmButtonClass:'button'
      // });
      this.instructionsURL=conf.instructions
    download.getNoticeDate()
    this.checkClient()
    let ua
    //APP端
    if(navigator.userAgent==='pan.baidu.com'&&this.$route.query.platform!==undefined&&this.$route.query.osVersion!==undefined){
          this.$store.commit('isAPP',true)
          ua={
            device:{
              type:'mobile'
            },
            browser:{
              name:'APP',
            },
            engine:{
              name:'APP'
            },
            os:{
              name:'APP'
            }
          }
    }else{
      ua = uaExtend(navigator.userAgent)
    }
    this.ua=ua
    this.$store.commit('ua',ua)
    this.device = this.ua.device.type
    this.initData()
    setTimeout(()=>{
      login.checkLocal({obj:this,
        success:(r)=>{
            this.showLoginModal=false
            this.searchQuery()
        },
      fail:(r)=>{
          console.log('fail')
      }})
    },0)
  },
  methods: {
      downloadInAPP(){
          //
          const url=this.$store.state.downloadActualURL
          const item=this.$store.state.currentItem
          const d=plus.downloader.createDownload(url, {
              retry:1,
              timeout:0
          },(r,status)=>{
              this.$store.commit('reportDownloading',{downloadedSize:r.downloadedSize,totalSize:r.totalSize,state:r.state,fileName:item.fileName,expectFileSize:item.fileSize,localPath:r.filename})
          } );
          d.addEventListener("statechanged",(r,status)=>{
              this.$store.commit('reportDownloading',{downloadedSize:r.downloadedSize,totalSize:r.totalSize,state:r.state,fileName:item.fileName,expectFileSize:item.fileSize,localPath:r.filename})
          } , false);
          d.setRequestHeader('User-Agent','pan.baidu.com')
          d.start();
      },
      download(item){
          if(!this.$store.state.loginStatus){
              this.showLoginModal=true
              return
          }
          this.loading=true
          download.download({obj:this,item:item,
          c:()=>{
              this.loading=false
          }})
      },
    logout(){
      login.logout({})
          this.$message({
              message: '退出登录成功',
              type: 'success',
              duration:5000,
          });
    },
    onConfirmNotice(){
        this.$message({
            message: this.$store.state.downloadErrMsg,
            type: 'error',
            duration:5000,
        });
    },
    browse(item){
        let targetParentPath
        if(item.isDir==='1'){
            targetParentPath=item.path+item.fileName+'/'
        }else{
            targetParentPath=rtrim(item.path,'/')+'/'
        }
        this.$refs.browser.show({
            db:item.DB,
            targetParentPath:targetParentPath
        })
    },
    shield(item){
      this.$store.commit('shieldItem',item)
      this.$store.commit('showShieldConfirm',true)
    },
    initData() {
      request({
        obj: this,
        path: 'init',
        success: (r) => {
          this.recommendItems = r.recommend
          this.status = r.status
          this.footerHtmls.desktop = r.html.pc_Instructions_at_footer
          this.footerHtmls.mobile = r.html.mb_Instructions_at_footer
          this.guides.pc = r.html.pc_Instructions_for_use
          this.guides.mb = r.html.mb_Instructions_for_use
          this.placeholders.pc = r.pc_placeholder
          this.placeholders.mb = r.mb_placeholder
          this.$store.commit('baseData',r)
          this.$store.commit('number1',r.number1)
        }
      })
    },
    searchQuery(){
      if (this.$route.query.keyword !== undefined) {
        const keyword = this.$route.query.keyword
        this.setKeyword(keyword)
        setTimeout(() => {
          this.submit(keyword, 0, undefined, {
            allFileTypes:false,
            certain: null,
            fileTypes: ["文档", "图片", "音频", "视频"],
            unrepeat: false
          })
        }, 0)
      }
    },
    login() {
          this.loginBtnLoading=true
      request({
        obj: this,
        path: 'login',
        data: {
          username: this.username,
          password: this.password,
            version:process.env.VUE_APP_VERSION
        },
          complete:()=>{
            this.loginBtnLoading=false
          },
        success: (r) => {
            this.showLoginModal=false
            this.$message({
                message: '登录成功',
                type: 'success',
                duration:5000,
            });
          login.afterLogin({
            uuid: r.uuid,
            token: r.token,
            isAdmin:r.isAdmin,
            number1:r.number1,
          })
          //this.initData()
        }
      })
    },
    rand(len) {
      const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789'
      const maxPos = chars.length
      let pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    },
    checkClient() {
      let clientId = localStorage.getItem('clientId')
      if (clientId === null) {
        const time = new Date().getTime()
        clientId = this.rand(6) + time
        localStorage.setItem('clientId', clientId)
      }
      this.clientId = clientId
    },
    submit(keyword, offset = 0, length, conf) {
      switch (keyword){
        case ':keywords':
        case '：keywords':
          this.$router.push({name:'shield_path'})
          return true
        case ':log':
        case '：log':
          this.$router.push({name:'statistics'})
          return true
        case ':resetpw':
        case '：resetpw':
          window.open('https://x.com/mb/pw/reset')
          return true
        case ':logout':
        case '：logout':
          this.logout()
          this.setKeyword('')
          return true
        case ':update':
        case '：update':
            this.loading=true
          //this.$router.push({name:'manage_kdoc_sync'})
            request({
              obj:this,
              path:'kdocs/update',
              success:(r)=>{
                if(typeof r.s!=='undefined'){
                  this.$msgbox('请关注企业微信群通知','')
                  this.setKeyword('')
                }
              },
              complete:(r)=>{
                this.loading=false
              }
            })
          return true
      }
      if (this.$route.query.keyword !== keyword) {
        const platform=this.$route.query.platform
        const osVersion=this.$route.query.osVersion
        const location={query: {keyword: JSON.parse(JSON.stringify(keyword))}}
        if(typeof platform!=='undefined'){
          location.query['platform']=platform
        }
        if(typeof osVersion!=='undefined'){
          location.query['osVersion']=osVersion
        }
        this.$router.replace(location)
      }
      if (conf.fileTypes.length === 0) {
        this.$message({
          message: '请至少勾选一种文件类型',
          type: 'warning'
        });
        return false
      }
      this.loading = true
      this.items = []
      if (length === undefined) {
        length = this.pageSize
      }
      request({
        obj:this,
        path:'search_5',
        data:{
          keyword: keyword,
          offset: offset,
          length: length,
          browser: this.ua.browser.name,
          device: this.ua.device.type,
          engine: this.ua.engine.name,
          os: this.ua.os.name,
          ua: JSON.parse(JSON.stringify(this.ua)),
          clientId: this.clientId,
          conf: conf,
        },
        success:(r)=>{
          $('#tab-0').click()
          this.items = r.items
          this.total = r.total
          this.status = r.status
          if (r.items.length === 0) {
            this.$message({
              message: '暂时没有相关内容',
              type: 'warning'
            });
          }
        },
        complete: () => {
          this.loading = false
        }
      })
    },
    clickRecommend(index) {
      const item = this.recommendItems[index]
      this.setKeyword(item.fileName)
      let conf
      if (this.device === 'mobile') {
        conf = this.$refs.r_mb.getConf()
      } else {
        conf = this.$refs.r_pc.getConf()
      }
      this.$nextTick(function () {
        this.submit(item.fileName, 0, undefined, conf)
      })
    },
    setKeyword(keyword) {
      if (this.$refs.r_mb) {
        this.$refs.r_mb.setKeyword(keyword)
      }
      if (this.$refs.r_pc) {
        this.$refs.r_pc.setKeyword(keyword)
      }
    }
  },
  data() {
    return {
        instructionsURL:null,
        loginBtnLoading:false,
      ignoreNotice:false,
      t1:null,
      contactUsImg: conf.contactUs.img,
      username: null,
      password: null,
      status: true,
      placeholders: {pc: null, mb: null},
      guides: {pc: null, mb: null},
      footerHtmls: {desktop: null, mobile: null},
      loading: false,
      ua: null,
      device: null,
      recommendItems: [],
      total: null,
      pageSize: 50,
      items: [],
      clientId: null,
      showLoginModal:true
    }
  },
  components: {
    Status,FloatingWindow,
    mb, pc, footerC,Browser
  },
  watch:{
    ignoreNotice(n){
      if(n===true){
        this.$store.commit('noticeWhenDownload',false)
        download.setNoticeDate()
      }
    },
      loginStatus(n){
        if(n===false){
            this.showLoginModal=true
        }
      }
  },
    computed:{
      ...mapState({
          loginStatus:state=>state.loginStatus
      })
    }
}
</script>
<style>

</style>
