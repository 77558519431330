export default {
    date(){
        const v=new Date()
        var month=v.getMonth()+1
        if((month+'').length===1){
            month='0'+month
        }
        var date=v.getDate()
        if((date+'').length===1){
            date='0'+date
        }
        return v.getFullYear()+'-'+month+'-'+date
    }
}