<template>
    <div style="width: 100%;">
        <div :style="{minWidth:'200px',width:screenWidth/2+'px',maxWidth:'600px',margin: '0 auto'}">
            <div style="text-align: center;margin: 0 0 35px 0;">
                <img :width="screenWidth/5.1+'px'" src="/img/title.png"/>
            </div>
                <div :style="{minWidth:'200px',width:screenWidth*22/40+'px',maxWidth:'600px',display:'inline-block'}">
                    <el-input class="kw_pc" v-model="keyword" :placeholder="placeholders.pc" :clearable="true" @keyup.enter.native="submit">
                        <template slot="append">
                            <div @click="submit">搜 索</div>
                        </template>
                    </el-input>
                </div>
        </div>
        <div style="text-align: center">
            <fileType ref="fileType" style="margin: 10px 0 0 0;"></fileType>
        </div>
    </div>
</template>
<script>
    import fileType from './fileType.vue'
    import $ from 'jquery'
    export default {
        props:['placeholders'],
        mounted() {
            this.$nextTick(function () {
                this.screenWidth=$(window).width()
            })
        },
        data() {
            return {
                keyword: null,
                searchDivWidth: 0,
                screenWidth:null,

            }
        },
        methods: {
            setConfAllSelected(){
                return this.$refs.fileType.setConfAllSelected()
            },
            getConf(){
                return this.$refs.fileType.getConf()
            },
            setKeyword(keyword) {
                this.keyword = keyword
            },
            submit() {
                this.$emit('submit', this.keyword,undefined,undefined,this.getConf())
            },
        },
        components:{
            fileType
        }
    }
</script>
<style>
    .kw_pc input{
        border: 2px solid #c4c7ce;
        border-radius: 10px 0 0 10px;
        font-size: 16px;
        vertical-align: bottom;
    }
    .kw_pc input:focus{
        border-color: #4e6ef2!important;
    }
    .kw_pc{
        border: unset !important;
    }
</style>