export default {
    api_domain:{
        //development:'http://192.168.1.101:8085/',
        //development:'http://127.0.0.1:8086/',
        development:'https://test.lib.fo/',
        production:window.origin+'/',
    },
    //联系我们图
    'contactUs':{
        img:''
    },
    instructions:'https://flowus.cn/share/c0a1bd7d-4837-4e32-8b57-f8cba2104b63'
}