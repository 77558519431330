<template>
    <img :src="src" width="24px" height="24px"/>
</template>
<script>
    export default {
        props:[
            'item'
        ],
        data(){
            return {
                src:null
            }
        },
        watch:{
            item:{
                deep:true,
                immediate:true,
                handler(n){
                    let src=null
                    if(n.fileSize==0)
                    {
                        src='/icons/icon6.png'
                    }else{
                        switch (n.fileType) {
                            case 'HTM':
                            case 'HTML':
                            case 'CHM':
                            case 'PDF':
                            case 'PDG':
                            case 'DOC':
                            case 'DOCX':
                            case 'XLS':
                            case 'XLSX':
                            case 'PPT':
                            case 'PPTX':
                            case 'UZV':
                                src='/icons/icon1.png'
                                break
                            case 'RAR':
                            case '7Z':
                            case 'ZIP':
                            case '0':
                            case '000':
                            case '001':
                            case 'ACE':
                            case 'AIN':
                            case 'ALZ':
                            case 'APZ':
                            case 'AR':
                            case 'ARC':
                            case 'ARI':
                            case 'ARJ':
                            case 'AXX':
                            case 'BH':
                            case 'BHX':
                            case 'BOO':
                            case 'BZ':
                            case 'BZA':
                            case 'BZ2':
                            case 'C00':
                            case 'C01':
                            case 'C02':
                            case 'CAB':
                            case 'CAR':
                            case 'CBR':
                            case 'CP9':
                            case 'CPGZ':
                            case 'CPT':
                            case 'DAR':
                            case 'DD':
                            case 'DGC':
                            case 'EFW':
                            case 'F':
                            case 'GCA':
                            case 'GZ':
                            case 'HA':
                            case 'HBC':
                            case 'HBC2':
                            case 'HBE':
                            case 'HKI':
                            case 'HKI1':
                            case 'HKI2':
                            case 'HKI3':
                            case 'HPK':
                            case 'HYP':
                            case 'ICE':
                            case 'IMP':
                            case 'IPK':
                            case 'ISH':
                            case 'JAR':
                            case 'JGZ':
                            case 'JIC':
                            case 'KGB':
                            case 'KZ':
                            case 'LBR':
                            case 'LHA':
                            case 'LNX':
                            case 'LQR':
                            case 'LZ4':
                            case 'LZH':
                            case 'LZM':
                            case 'LZMA':
                            case 'LZO':
                            case 'LZX':
                            case 'MINT':
                            case 'MOU':
                            case 'MPKG':
                            case 'MZP':
                            case 'NZ':
                            case 'P7M':
                            case 'PACKAGE':
                            case 'PAE':
                            case 'PAK':
                            case 'PAQ6':
                            case 'PAQ7':
                            case 'PAQ8':
                            case 'PAR':
                            case 'PAR2':
                            case 'PBI':
                            case 'PCV':
                            case 'PEA':
                            case 'PF':
                            case 'PIM':
                            case 'PIT':
                            case 'PIZ':
                            case 'PUZ':
                            case 'PWA':
                            case 'QDA':
                            case 'R00':
                            case 'R01':
                            case 'R02':
                            case 'R03':
                            case 'RNC':
                            case 'RK':
                            case 'RPM':
                            case 'RTE':
                            case 'RZ':
                            case 'RZS':
                            case 'S00':
                            case 'S01':
                            case 'S02':
                            case 'S7Z':
                            case 'SAR':
                            case 'SDN':
                            case 'SEA':
                            case 'SFS':
                            case 'SFX':
                            case 'SH':
                            case 'SHAR':
                            case 'SHK':
                            case 'SHR':
                            case 'SIT':
                            case 'SITX':
                            case 'SPT':
                            case 'SQX':
                            case 'SQZ':
                            case 'TAR':
                            case 'TBZ':
                            case 'TBZ2':
                            case 'TGZ':
                            case 'TLZ':
                            case 'TLZ4':
                            case 'TXZ':
                            case 'UC2':
                            case 'UHA':
                            case 'UUE':
                            case 'WOT':
                            case 'XEF':
                            case 'XX':
                            case 'XXE':
                            case 'XZ':
                            case 'Y':
                            case 'YZ':
                            case 'YZ1':
                            case 'Z':
                            case 'ZAP':
                            case 'ZIPX':
                            case 'ZIX':
                            case 'ZOO':
                            case 'ZZ':
                            case 'EXE':
                                src='/icons/icon2.png'
                                break
                            case 'MP4':
                            case 'AVI':
                            case 'FLV':
                            case 'MPEG':
                            case 'MPG':
                            case 'DAT':
                            case 'MOV':
                            case 'ASF':
                            case 'WMV':
                            case 'NAVI':
                            case '3GP':
                            case 'RA':
                            case 'RAM':
                            case 'MKV':
                            case 'F4V':
                            case 'RMVB':
                            case 'WEBM':
                            case 'HDDVD':
                            case 'BD':
                            case 'QSV':
                                src='/icons/icon3.png'
                                break
                            case 'MP3':
                                src='/icons/icon4.png'
                                break
                            case 'JPG':
                            case 'PNG':
                            case 'BMP':
                            case 'GIF':
                            case 'JPEG':
                            case 'JPEG2000':
                            case 'TIFF':
                            case 'PSD':
                            case 'SWF':
                            case 'SVG':
                            case 'PCX':
                            case 'WMF':
                            case 'EMF':
                            case 'LIC':
                            case 'FLI':
                            case 'FLC':
                            case 'EPS':
                            case 'TGA':
                                src='/icons/icon5.png'
                                break
                            default:
                                src='/icons/icon1.png'
                        }
                    }
                    this.src=src
                }
            }
        }
    }
</script>