<template>
    <div>
        <el-row>
            <el-col :span="22" :offset="1">
                <div style="text-align: center;">
                    <img style="margin: 0 0 15px 0;" :width="screenWidth/1.7+'px'" src="/img/title.png"/>
                    <div>
                        <div :style="{width:screenWidth*20/28+'px',display:'inline-block'}">
                            <el-input class="kw_pc" v-model="keyword" :placeholder="placeholders.mb" :clearable="true" @keyup.enter.native="submit">
                                <template slot="append">
                                    <div @click="submit">搜 索</div>
                                </template>
                            </el-input>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div style="text-align: center">
            <fileType ref="fileType" style="margin: 10px 0 0 0;"></fileType>
        </div>
    </div>
</template>
<script>
    import fileType from '../mb/fileType.vue'
    import $ from 'jquery'
    export default {
        props:['placeholders'],
        mounted() {
            this.$nextTick(function () {
                this.screenWidth=$(window).width()
            })
        },
        data(){
            return {
                keyword:null,
                screenWidth:null,
            }
        },
        methods:{
            setConfAllSelected(){
                return this.$refs.fileType.setConfAllSelected()
            },
            getConf(){
                return this.$refs.fileType.getConf()
            },
            setKeyword(keyword){
                this.keyword=keyword
            },
            submit(){
                this.$emit('submit', this.keyword,undefined,undefined,this.getConf())
            },
        },components:{
            fileType
        }
    }
</script>