<template>
    <div style="position:relative;border: 1px solid #dedede;margin: 5px 0 0 0;padding: 0 0 5px 0;">
        <div style="margin: 10px 0 0 10px;">
            <div style="display: inline-block;font-size:20px;top: 0;left: 0;position: relative;padding: 0 5px 0 0;overflow-wrap: anywhere;">
                <iconC style="display: inline-block;" :item="item"></iconC>
                <span style="position: relative;top: -5px;left: 5px;">
                    <span v-html="fileNameHtml"></span>
                </span>
            </div>
        </div>
        <div style="display: inline-block;font-size:12px;color: gray;margin: 0 0 0 10px" v-html="pathHtml"></div>
        <div style="font-size:12px;color: gray;margin: 5px 0 0 0;">
            <div>
              <el-tag v-if="item.isDir=='0'" @click="download" style="margin-left: 10px;" type="error" effect="plain" size="mini">
                下载
              </el-tag>
                <el-tag @click="browse(item)" style="margin-left: 10px;" type="info" effect="plain" size="mini">
                    浏览目录
                </el-tag>
                <el-tag v-show="item.fileType" style="margin-left: 10px;" type="success" effect="dark" size="mini">
                    {{ item.fileType }}
                </el-tag>
                <el-tag v-show="item.fileSize!=0" style="margin-left: 10px;" type="warning" effect="dark" size="mini">
                    {{ fileSizeText}}
                </el-tag>
              <el-tag v-show="$store.state.isAdmin"  @click="shield" style="margin-left: 10px;background-color: red;color:white;border:0;font-weight: 100;" type="error" effect="plain" size="mini">
                屏蔽
              </el-tag>
            </div>
            <div>
                <el-tag v-show="item.md5&&item.md5.length===32" style="margin:5px 0 0 10px;" type="info" effect="dark" size="mini">
                    MD5:{{item.md5}}
                </el-tag>
            </div>
        </div>
    </div>
</template>

<script>
    import iconC from '../icon.vue'
    import download from "../../extend/download.js";
    import search from '../../plugin/search.js'
    export default {
        props:['item','keyword'],
        data(){
            return {
                fileNameHtml:null,
                pathHtml:null,
                highLightClassName:'FileName-HighLight',
                fileSizeText:null,
            }
        },
        methods:{
          shield(){
            this.$emit('shield',this.item)
          },
          browse(item){
            this.$emit('browse',item)
          },
          download(){
              this.$emit('download',this.item)
          },
            onCopy(){
                this.$message({
                    message: '提取码复制成功！',
                    type: 'success'
                });
            },
            onError(){
                this.$message({
                    message: '提取码复制失败！',
                    type: 'error'
                });
            },
            createFileNameHtml(fileName){
                return search.createFileNameHtml({
                    fileName:fileName,
                    keyword:this.keyword,
                    highLightClassName:this.highLightClassName
                })
            },
        },
        watch:{
            item:{
                deep:true,
                immediate:true,
                handler(n){
                    let fileSizeText=(n.fileSize/1024/1024/1024).toFixed(0)
                    if(fileSizeText<1)
                    {
                        fileSizeText=(n.fileSize/1024/1024).toFixed(0)
                        if(fileSizeText<1)
                        {
                            fileSizeText=(n.fileSize/1024).toFixed(0)
                            if(fileSizeText<1)
                            {
                                fileSizeText=n.fileSize+'B'
                            }else{
                                fileSizeText=fileSizeText+'KB'
                            }
                        }else{
                            fileSizeText=fileSizeText+'MB'
                        }
                    }else{
                        fileSizeText=fileSizeText+'GB'
                    }
                    this.fileSizeText=fileSizeText
                    if(n.fileName)
                    {
                      this.fileNameHtml=this.createFileNameHtml(n.fileName)
                    }
                  if(n.path)
                  {
                    this.pathHtml=this.createFileNameHtml(n.path)
                  }
                }
            },

        },
        components:{
            iconC
        }
    }
</script>