import file from "../plugin/file";
export default {
    //success存在 fail不存在
    ifDirExists({obj,path,success,fail}){
        new Promise((resolve,reject)=>{
            plus.io.resolveLocalFileSystemURL(path,
                (r)=>{
                    if(r.isDirectory===true){
                        resolve()
                    }else{
                        reject()
                    }
                },
                (r)=>{
                    if(r.code===14){
                        reject()
                    }else{
                        obj.$message({
                            message:r.message,
                            type:'error'
                        })
                    }
                })
        }).then((r)=>{
            if(typeof success!=='undefined'){
                success()
            }
        }).catch((r)=>{
            if(typeof fail!=='undefined'){
                fail()
            }
        })
    },
    //获取设备的下载目录 success({path}) fail未找到
    checkDownloadDir({obj,success,fail}){
        this.ifDirExists({
            obj:obj,
            path:'/sdcard/download/',
            success:()=>{
                if(typeof success!=='undefined'){
                    success({path:'/sdcard/download/'})
                }
            },
            fail:()=>{
                this.ifDirExists({
                    obj:obj,
                    path:'/sdcard/downloads/',
                    success:()=>{
                        if(typeof success!=='undefined'){
                            success({path:'/sdcard/downloads/'})
                        }
                    },
                    fail:()=>{
                        if(typeof fail!=='undefined'){
                            fail()
                        }
                    }
                })
            }
        })
    },
    moveFile({source,targetDir,success,fail}){
        console.log(source)
        console.log(targetDir)
        console.log(file.getFileName({path:source}))
        plus.io.resolveLocalFileSystemURL(source, (r1) => {
            plus.io.resolveLocalFileSystemURL(targetDir,
                (r2) => {
                    r1.moveTo(r2, file.getFileName({path:source}),
                        (r) => {
                            if(typeof success!=='undefined'){
                                success()
                            }
                        },
                        (r) => {
                            console.log(1,r)
                            if(typeof fail!=='undefined'){
                                fail(r)
                            }
                        });
                },
                (r) => {
                    console.log(2,r)
                    if(typeof fail!=='undefined'){
                        fail(r)
                    }
                })
        },
        (r) => {
            console.log(3,r)
            if(typeof fail!=='undefined'){
                fail(r)
            }
        });
    }
}