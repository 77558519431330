import store from '../store'
import request from '../plugin/request'
export default {
    logout({}){
        store.commit('uuid',null)
        store.commit('token',null)
        store.commit('loginStatus',false)
        localStorage.setItem('uuid',null)
        localStorage.setItem('token',null)
    },
    afterLogin({uuid,token,isAdmin=false,number1}){
        store.commit('number1',number1)
        store.commit('uuid',uuid)
        store.commit('token',token)
        store.commit('isAdmin',isAdmin)
        store.commit('loginStatus',true)
        localStorage.setItem('uuid',uuid)
        localStorage.setItem('token',token)
        localStorage.setItem('isAdmin',isAdmin)
    },
    checkLocal({obj,success,fail}){
        if(store.state.loginStatus===true){
            return true
        }
        const uuid=localStorage.getItem('uuid')
        const token=localStorage.getItem('token')
        if(uuid===null||token===null){
            if(typeof fail!=='undefined'){
                fail()
            }
            return false
        }else{
            //obj.loading=true
            this.checkLogin({obj:obj,uuid:uuid,token:token,success:success,fail:fail})
            //obj.loading=false
        }
    },
    checkLogin({obj,uuid,token,success,fail}){
        request({
            obj:obj,
            path:'login/check',
            data:{
                uuid:uuid,
                token:token,
                version:process.env.VUE_APP_VERSION
            },
            success:(r)=>{
                if(typeof r.s1!=='undefined'){
                    if(typeof fail!=='undefined'){
                        fail()
                    }
                }
                if(typeof r.s!=='undefined'){
                    if(typeof  obj!=='undefined'&& obj.$message!=='undefined'){
                        obj.$message({
                            message: '自动登录成功',
                            type: 'success',
                            duration:5000,
                        });
                    }
                    this.afterLogin({
                        uuid:uuid,
                        token:token,
                        isAdmin:r.s.isAdmin,
                        number1:r.s.number1
                    })
                    if(typeof success!=='undefined'){
                        success(r)
                    }
                }
            }
        })
    }
}