<template>
    <div>
        <div>
            <el-dialog
                :top="device==='mobile'?'4vh':'10vh'"
                :show-close="true"
                :close-on-press-escape="true"
                :close-on-click-modal="true"
                title="浏览目录"
                :modal="true"
                :visible.sync="visible"
                :width="device==='mobile'?'100%':'80%'"
                center>
                <div v-loading="loading">
                    <div v-if="loading===false"
                         style="font-size: small;color:gray;text-align: center;margin:0 0 10px 0;">目录可以点击跳转
                    </div>
                    <div style="font-size: 18px;margin:0 0 10px 0;cursor: pointer;user-select: none">
                        <span
                            v-for="(item,index) in targetParentPath"
                            :key="index"
                            :style="{color:'#f25623'}"
                            >
                            <span v-if="index<targetParentPath.length-1" @click="showDir({db:item.DB,parentPath:item.path==='/'?'/':item.path+'/'})">{{ item.text}}</span>
                            <span v-else style="color:black;cursor: default">{{ item.text}}</span>
                            <span style="color:black;font-weight: 600;margin:0 4px;">/</span>
                        </span>
                    </div>
                    <div>
                        <div v-for="(i,index) in items" :key="index"
                             style="border: 1px solid #cecdcd;padding: 10px 20px;border-radius: 4px;">
                            <div @click="clickItem(i)"
                                 style="font-size: 18px;padding:4px 0;cursor: pointer;">
                                <iconC style="display: inline-block;position: relative;top: 6px;" :item="i"></iconC>
                                {{ i.fileName }}
                            </div>
                            <div>
                                <el-tag v-if="i.isDir===0" style="margin:0 20px 0 0;cursor: pointer" type="success"
                                        effect="dark" size="medium" @click="download(i)">下载
                                </el-tag>
                                <el-tag v-if="i.fileSize>0" type="warning" effect="dark" size="medium">
                                    {{ i.filesizeText }}
                                </el-tag>
                                <el-tag v-if="$store.state.isAdmin"
                                        style="margin:0 0 0 20px;cursor: pointer;background-color: red;color:white;border:0;"
                                        effect="dark" size="medium" @click="shield(i)">屏蔽
                                </el-tag>
                            </div>
                        </div>
                        <div v-if="loading===false&&items.length===0" style="color:gray;">空目录</div>
                    </div>
                </div>
            </el-dialog>
            <!--    屏蔽-->
            <el-dialog
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :modal="true"
                :visible.sync="$store.state.showShieldConfirm"
                :width="device==='mobile'?'90%':'30%'"
                center>
                <div>
                    <div>
                        <el-input v-model="shieldCommit" placeholder="可选">
                            <template #prepend>
                                屏蔽备注
                            </template>
                        </el-input>
                    </div>
                    <div style="margin:20px 0 0 0;">
                        <el-button class="button" @click="submitShieldPath" style="width: 100%" type="primary">确定</el-button>
                    </div>
                    <div style="margin:20px 0 0 0;">
                        <el-button @click="$store.commit('showShieldConfirm',false)" style="width: 100%" type="info">
                            取消
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import iconC from '../components/icon'
import request from "../plugin/request";
import file from '../plugin/file'
import download from "../extend/download";

export default {
    props: {
        device: {
            default: 'desktop'
        }
    },
    data() {
        return {
            shieldCommit: null,
            items: [],
            parentPath: null,
            serverFilename: null,
            visible: false,
            loading: true,
            targetParentPath: {},
        }
    },
    methods: {
        clickItem(item){
            if(item.isDir===1){
                this.showDir({parentPath:item.path+item.fileName+'/'})
            }
        },
        submitShieldPath() {
            this.loading = true
            this.$store.commit('showShieldConfirm', false)
            request({
                obj: this,
                path: 'shield/path',
                data: {
                    path: this.$store.state.shieldItem.path + this.$store.state.shieldItem.fileName,
                    isDir: this.$store.state.shieldItem.isDir,
                    commit: this.shieldCommit,
                },
                success: (r) => {
                    if (typeof r.s !== 'undefined') {
                        this.$msgbox('屏蔽成功', '')
                        this.shieldCommit = null
                    }
                },
                complete: () => {
                    this.loading = false
                }
            })
        },
        shield(item) {
            this.$emit('shield', item)
        },
        showDir({db,parentPath}) {
            this.show({db:db,targetParentPath: parentPath})
        },
        download(item) {
            this.$emit('download', item)
        },
        setVisible(v) {
            this.visible = v
        },
        show({db,targetParentPath}) {
            this.setVisible(true)
            this.browse({db:db,targetParentPath: targetParentPath})
        },
        browse({db,targetParentPath}) {
            this.loading = true
            this.items = []
            this.targetParentPath = {}
            request({
                obj: this,
                path: 'browse',
                data: {
                    db:db,
                    targetParentPath: targetParentPath,
                },
                success: (r) => {
                    this.loading = false
                    if (typeof r.s1 !== 'undefined') {
                        this.$message({
                            message: '没有找到该文件',
                            type: 'error',
                        })
                    }
                    if (typeof r.s !== 'undefined') {
                        this.items = r.s.items
                        const targetParentPath = [
                            {
                                text:'根目录',
                                path: '/'
                            }
                        ]
                        const targetParentPathArr = r.s.targetParentPath.split('/')
                        const targetParentPathArrLength=targetParentPathArr.length
                        let basePath = ''
                        for (const index in targetParentPathArr) {
                            if (targetParentPathArr[index] !== '') {
                                basePath = basePath + '/' + targetParentPathArr[index]
                                let item={
                                    text:targetParentPathArr[index],
                                    path:basePath,
                                    isLastOne:false
                                }
                                if(targetParentPathArrLength===index*1+2){
                                    item['isLastOne']=true
                                }
                                targetParentPath.push(item)
                            }
                        }
                        this.targetParentPath = targetParentPath
                    }
                }
            })
        }
    },
    watch: {
        items: {
            handler(n) {
                for (const index in n) {
                    n[index]['filesizeText'] = file.filesizeText(n[index]['fileSize'])
                }
            }
        }
    },
    components: {
        iconC
    }
}
</script>