import Vue from 'vue'
import Vuex from 'vuex'
import time from "../plugin/time";
import conf from "@/conf";
Vue.use(Vuex);
export default new Vuex.Store({
    state:{
        //登录状态
        loginStatus:false,
        uuid:null,
        token:null,
        isAdmin:null,
        //当前是否APP访问
        isAPP:false,
        //APP用
        downloading:{
            //0: (Number 类型 )下载任务开始调度
            // 1: (Number 类型 )下载任务开始请求
            // 2: (Number 类型 )下载任务请求已经接收
            // 3: (Number 类型 )下载任务接收数据
            // 4: (Number 类型 )下载任务已完成
            // 5: (Number 类型 )下载任务已暂停
            //-1 非下载任务状态
            state:-1,
            name:null,
            downloadedSize:0,
            totalSize:0,
            //期待的文件大小
            expectFileSize:0,
            //下载存储到本地的路径
            localPath:null,
        },
        //基础数据
        baseData:{
            html:{
                download_confirm:''
            }
        },
        //剩余下载次数
        number1:null,
        //显示下载确认弹窗
        showDownloadConfirm:false,
        //不需提示的日期
        noticeDate:null,
        //是否下载提示
        noticeWhenDownload:true,
        //正在下载的item
        currentItem:null,
        showShieldConfirm:false,
        shieldItem:null,
        ua:null,
        downloadErrMsg:null,
        downloadActualURL:null,
        downloadBtnRedirect:false,
    },
    mutations:{
        //v:{downloadedSize,totalSize,state,fileName,expectFileSize,localPath}
        reportDownloading(state,v){
            state.downloading.state=v.state
            state.downloading.totalSize=v.totalSize
            state.downloading.downloadedSize=v.downloadedSize
            state.downloading.fileName=v.fileName
            state.downloading.expectFileSize=v.expectFileSize
            state.downloading.localPath=v.localPath
        },
        loginStatus(state,v){
            state.loginStatus=v
        },
        downloadErrMsg(state,v){
            state.downloadErrMsg=v
        },
        downloadBtnRedirect(state,v){
            state.downloadBtnRedirect=v
        },
        downloadActualURL(state,v){
            state.downloadActualURL=v
        },
        showShieldConfirm(state,v){
            state.showShieldConfirm=v
        },
        ua(state,v){
            state.ua=v
        },
        shieldItem(state,v){
            state.shieldItem=v
        },
        currentItem(state,v){
            state.currentItem=v
        },
        noticeDate(state,v){
            state.noticeDate=v
            if(v===time.date()){
                state.noticeWhenDownload=false
            }
        },
        baseData(state,v){
            state.baseData=v
        },
        noticeWhenDownload(state,v){
            state.noticeWhenDownload=v
        },
        showDownloadConfirm(state,v){
            state.showDownloadConfirm=v
        },
        uuid(state,v){
            state.uuid=v
        },
        number1(state,v){
            state.number1=v
        },
        token(state,v){
            state.token=v
        },
        isAdmin(state,v){
            state.isAdmin=v
        },
        isAPP(state,v){
            state.isAPP=v
        }
    },
    actions:{
        beforeLeaveTab(c,{tabIndex}){
            return new Promise((s,f)=>{
                if(tabIndex==='1'){
                    window.open(conf.instructions,'_blank')
                    f()
                }else{
                    s()
                }
            })
        }
    }
})
