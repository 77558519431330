<template>
    <div>
        <item @browse="browse" @shield="shield" v-for="(i,index) in items" :key="index" :item="i" :keyword="keyword" @download="download"></item>
        <el-pagination
                style="margin: 0 0 50px 0;"
                v-show="(items.length===0&&currentPage!==1)||items.length>0"
                layout="prev, pager, next"
                @current-change="currentChange"
                :total="total"
                :page-size="pageSize"
                :current-page.sync="currentPage"
        >
        </el-pagination>
    </div>
</template>
<script>
    import item from './item.vue'
    export default {
        props:['total','pageSize','items','keyword'],
        data(){
            return {
                currentPage:null
            }
        },
        methods:{
            download(item){
                this.$emit('download',item)
            },
          shield(item){
            this.$emit('shield',item)
          },
          browse(item){
            this.$emit('browse',item)
          },
            currentChange(n){
                this.$emit('pageChange',n)
            },
            setCurrentPage(n){
                //console.log(n)
                this.currentPage=n
            },
        },
        components:{
            item
        }
    }
</script>