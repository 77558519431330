<template>
    <div>
        <el-checkbox style="display: inline-block" :indeterminate="isIndeterminate" v-model="allFileTypes" @change="fileTypesCheckAllChange">全选</el-checkbox>
        <el-checkbox-group style="display: inline-block;margin: 0 0 0 8px;"  v-model="fileTypes" @change="fileTypesCheckChange">
            <el-checkbox v-for="(fileType,index) in fileTypesArr" :label="fileType" :key="index">{{fileType}}</el-checkbox>
            <el-input class="certain" v-model="certain" style="display: inline-block;width: 60px;margin: 0 0 0 30px;position: relative;top: -7px;" :clearable="true" type="text" size="mini" placeholder="指定类型"></el-input>
            <hr style="display: inline-block;width: 60px;position: relative;left: -60px;top: 6px;"/>
        </el-checkbox-group>
        <el-checkbox style="position: relative;left: -25px;" v-model="unrepeat">排除重复文件</el-checkbox>
    </div>
</template>
<script>
    export default {
        methods:{
            setConfAllSelected(){
                this.unrepeat=false
                this.certain=null
                this.allFileTypes=true
                this.fileTypes=this.fileTypesArr
                return this.getConf()
            },
            getConf(){
                return {
                    unrepeat: this.unrepeat,
                    certain:this.certain,
                    allFileTypes:this.allFileTypes,
                    fileTypes:this.fileTypes,
                }
            },
            fileTypesCheckAllChange(val){
                //console.log(val)
                this.fileTypes = val ? this.fileTypesArr : [];
                this.isIndeterminate = false
            },
            fileTypesCheckChange(val){
                let checkedCount = val.length
                this.allFileTypes = checkedCount === this.fileTypesArr.length
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.fileTypesArr.length;
            },
        },
        data(){
            return {
                unrepeat:false,
                certain:null,
                isIndeterminate:true,
                allFileTypes:false,
                fileTypes:['文档','图片','音频','视频'],
                fileTypesArr:['文件夹','文档','图片','音频','视频'],
            }
        },
      // watch:{
      //   unrepeat(n){
      //     console.log(n)
      //   }
      // }
    }
</script>
<style>
    /*.certain input::placeholder{
        position: relative;
        top: 5px;
    }*/
    .certain .el-input__suffix{
        top: 6px;
        left: 50px;
    }
    .certain input{
        position: relative;
        top: 6px;
        text-align: center;
        padding: 0 !important;
        border-radius: unset;
        border-bottom: unset;
        border-top: unset;
        border-left: unset;
        border-right: unset;
    }
</style>