<template>
    <div style="border: 1px solid #dedede;margin: 5px 0 0 0;padding: 12px 0 12px 0;position: relative;">
        <div style="margin: 0 0 5px 10px;">
            <iconC style="display: inline-block;" :item="item"></iconC>
            <div style="display: inline-block;font-size:20px;margin: 0 0 0 5px;position: relative;top: -6px;">
                <div v-html="fileNameHtml"></div>
            </div>
        </div>
        <div style="font-size:12px;color: gray;padding: 0 5px 0 40px;" v-html="pathHtml"></div>
        <div style="font-size:12px;color: gray;margin: 5px 0 0 38px;">
            <el-tag v-show="item.fileType" type="success" effect="dark" size="mini">
                {{ item.fileType }}
            </el-tag>
            <el-tag v-show="item.fileSize!=0" style="margin-left: 10px;" type="warning" effect="dark" size="mini">
                {{ fileSizeText }}
            </el-tag>
            <el-tag v-show="item.md5&&item.md5.length===32" style="margin-left: 10px;" type="info" effect="dark"
                    size="mini">
                MD5:{{ item.md5 }}
            </el-tag>
            <el-tag v-show="$store.state.isAdmin" type="error"
                    style="margin-left: 10px;background-color: red;border: 0;cursor: pointer" effect="dark" size="mini"
                    @click="shield">
                屏蔽
            </el-tag>
        </div>
        <div
            style="display: inline-block;font-size:20px;top: 10px;right: 10px;float: right;position: absolute;cursor: pointer;">
            <el-tag @click="browse(item)" style="font-size: 20px;margin:0 10px 0 0;" type="info" effect="plain"
                    size="medium">
                浏览目录
            </el-tag>
            <el-tag v-if="item.isDir=='0'" @click="download" style="font-size: 20px;" type="error" effect="plain"
                    size="medium">
                下载
            </el-tag>
        </div>
    </div>
</template>

<script>
import download from '../../extend/download.js'
import iconC from '../icon.vue'
import search from '../../plugin/search.js'
export default {
    props: ['item', 'keyword'],
    data() {
        return {
            fileNameHtml: null,
            highLightClassName: 'FileName-HighLight',
            fileSizeText: null,
            pathHtml: null,
        }
    },
    methods: {
        browse(item) {
            this.$emit('browse', item)
        },
        shield() {
            this.$emit('shield', this.item)
        },
        download() {
            this.$emit('download', this.item)
            //download.download({item:this.item,obj:this})
        },
        onCopy() {
            this.$message({
                message: '提取码复制成功！',
                type: 'success'
            });
        },
        onError() {
            this.$message({
                message: '提取码复制失败！',
                type: 'error'
            });
        },
        createFileNameHtml(fileName) {
            return search.createFileNameHtml({
                fileName:fileName,
                keyword:this.keyword,
                highLightClassName:this.highLightClassName
            })
        },
    },
    watch: {
        item: {
            deep: true,
            immediate: true,
            handler(n) {
                let fileSizeText = (n.fileSize / 1024 / 1024 / 1024).toFixed(0)
                if (fileSizeText < 1) {
                    fileSizeText = (n.fileSize / 1024 / 1024).toFixed(0)
                    if (fileSizeText < 1) {
                        fileSizeText = (n.fileSize / 1024).toFixed(0)
                        if (fileSizeText < 1) {
                            fileSizeText = n.fileSize + 'B'
                        } else {
                            fileSizeText = fileSizeText + 'KB'
                        }
                    } else {
                        fileSizeText = fileSizeText + 'MB'
                    }
                } else {
                    fileSizeText = fileSizeText + 'GB'
                }
                this.fileSizeText = fileSizeText
                if (n.fileName) {
                    this.fileNameHtml = this.createFileNameHtml(n.fileName)
                }
                if (n.path) {
                    this.pathHtml = this.createFileNameHtml(n.path+n.fileName)
                }
            }
        },
    },
    components: {
        iconC
    }
}
</script>
<style>
.FileName-HighLight {
    color: #ff5a14;;
}
</style>