<template>
    <el-card v-show="recommendItems&&recommendItems.length>0" style="margin-top: 10px;" class="box-card" shadow="never">
        <div v-for="row in rows" :key="row" style="margin: 0 0 20px 0;">
            <div :style="{width:Math.floor(100/columns)+'%'}" v-if="recommendItems[(row-1)*columns]"
                 class="recommendDiv" @click="clickRecommend((row-1)*columns)">
                <div class="recommendItem">{{recommendItems[(row-1)*columns]['fileName']}}</div>
            </div>
            <div :style="{width:Math.floor(100/columns)+'%'}" v-if="recommendItems[(row-1)*columns+1]"
                 class="recommendDiv" @click="clickRecommend((row-1)*columns+1)">
                <div class="recommendItem">{{recommendItems[(row-1)*columns+1]['fileName']}}</div>
            </div>
            <div :style="{width:Math.floor(100/columns)+'%'}" v-if="recommendItems[(row-1)*columns+2]"
                 class="recommendDiv" @click="clickRecommend((row-1)*columns+2)">
                <div class="recommendItem">{{recommendItems[(row-1)*columns+2]['fileName']}}</div>
            </div>
        </div>
    </el-card>
</template>
<script>
    export default {
        props: [
            'recommendItems'
        ],
        methods: {
            clickRecommend(index) {
                this.$emit('clickRecommend', index)
            }
        },
        data() {
            return {
                columns: 3,
                rows: null,
            }
        },
        watch: {
            recommendItems: {
                handler(n) {
                    this.rows = Math.ceil(n.length / this.columns)
                },
                immediate: true,
            }
        }
    }
</script>